<template>
  <div>
    <div v-if="ispc" class="page">
      <div>
        <img src="../image/shouye_banner.png" style="width: 100%;">

      </div>
      <!-- <div class="top" id="boxFixed" :class="{'is_fixed' : isFixed}">
      <div style="display: flex;width: 20%;">
        <div>
          <img
            src="../image/top1.png"
            style="border-radius: 10px;"
          >
        </div>
        <div style="margin-left: 10px;font-size: 30px;font-weight: bold;color: #000;">
          众聚生活
        </div>
      </div>
      <div style="width: 60%;">
        <el-menu
          :default-active="activeIndex2"
          class="el-menu-demo"
          mode="horizontal"
          @select="handleSelect"
          text-color="#000"
          active-text-color="#ffd04b"
        >
          <el-menu-item index="1">首页</el-menu-item>
          <el-menu-item index="2">和众聚生活合作</el-menu-item>
          <el-menu-item index="3">我们的科技</el-menu-item>
          <el-submenu index="4">
            <template slot="title">企业社会责任</template>
<el-menu-item index="4-1">选项1</el-menu-item>
<el-menu-item index="4-2">选项2</el-menu-item>
<el-menu-item index="4-3">选项3</el-menu-item>
<el-submenu index="4-4">
  <template slot="title">选项4</template>
  <el-menu-item index="4-4-1">选项1</el-menu-item>
  <el-menu-item index="4-4-2">选项2</el-menu-item>
  <el-menu-item index="4-4-3">选项3</el-menu-item>
</el-submenu>
</el-submenu>
<el-submenu index="5">
  <template slot="title">众聚生活公益</template>
  <el-menu-item index="5-1">选项1</el-menu-item>
  <el-menu-item index="5-2">选项2</el-menu-item>
  <el-menu-item index="5-3">选项3</el-menu-item>
  <el-submenu index="5-4">
    <template slot="title">选项4</template>
    <el-menu-item index="5-4-1">选项1</el-menu-item>
    <el-menu-item index="5-4-2">选项2</el-menu-item>
    <el-menu-item index="5-4-3">选项3</el-menu-item>
  </el-submenu>
</el-submenu>
<el-menu-item index="6">投资者关系</el-menu-item>
<el-menu-item index="7">加入我们</el-menu-item>
</el-menu>
</div>
<div style="width: 20%;">
  <el-menu :default-active="activeIndex3" class="el-menu-demo" mode="horizontal" @select="handleSelect1"
    text-color="#000" active-text-color="#ffd04b">
    <el-submenu index="1">
      <template slot="title">下载和关注</template>
      <el-menu-item index="1-1">选项1</el-menu-item>
      <el-menu-item index="1-2">选项2</el-menu-item>
      <el-menu-item index="1-3">选项3</el-menu-item>
      <el-submenu index="1-4">
        <template slot="title">选项4</template>
        <el-menu-item index="1-4-1">选项1</el-menu-item>
        <el-menu-item index="1-4-2">选项2</el-menu-item>
        <el-menu-item index="1-4-3">选项3</el-menu-item>
      </el-submenu>
    </el-submenu>
    <el-submenu index="2">
      <template slot="title">简体中文</template>
      <el-menu-item index="2-1">选项1</el-menu-item>
      <el-menu-item index="2-2">选项2</el-menu-item>
      <el-menu-item index="2-3">选项3</el-menu-item>
      <el-submenu index="2-4">
        <template slot="title">选项4</template>
        <el-menu-item index="2-4-1">选项1</el-menu-item>
        <el-menu-item index="2-4-2">选项2</el-menu-item>
        <el-menu-item index="2-4-3">选项3</el-menu-item>
      </el-submenu>
    </el-submenu>
  </el-menu>
</div>
</div> -->
      <div class="middle" style="margin-top: -393px;">
        <div style="width: 10%;"></div>

        <div style="width:80%;">

          <div class="text1" style="font-size: 40px;font-weight: bold;color: #000;">
            众聚生活是一家科技服务公司
          </div>
          <div class="text1" style="font-size: 23px;color: #302f2f;margin-top: 31px;display: flex;justify-content: center;">
            众聚生活以“科技+服务”的战略
          </div>
          <div class="text1" style="font-size: 23px;color: #302f2f;margin-top: 8px;display: flex;justify-content: center;">
            让实体更赚钱，让用户更省钱，让生意更好做，让生活更美好
<!--            践行“让实体店利益最大化，让消费者得到优质的服务，让同城没有难做的生意”的公司使命。-->
          </div>
          <!-- <div
          class="text1"
          style="font-size: 13px;color: #909399;margin: 10px 0px;"
        >
          自2010年3月成立以来，众聚生活持续推动服务零售和商品零售在需求侧和供给侧的数字化升级，和广大合作伙伴一起努力为消费者提供品质服务。2018年9月20日，众聚生活在港交所挂牌上市。
        </div> -->
          <div class="text1" style="font-size: 13px;color: #909399;margin-top: 8px;margin-bottom:28px">
            在本地实体经济的基础上迭代升级，再反哺到市场，倾力打造本地实体经济的命运共同体，力争做到取之于民用之于民，携手共做本地良知企业。
          </div>
          <el-popover placement="bottom" width="150" trigger="hover">
            <!-- <el-button slot="reference">hover 激活</el-button> -->
            <div slot="reference" class="text1" style="font-size: 25px;color: #302f2f;margin-top: 25px;"
              @click="qrcode()">
              <!-- <i class="../image/iconerwm.png"></i> -->
              <img src="../image/iconerwm.png" style="width: 30px;height: 30px;">
              <div style="display: flex;align-items: center;margin-left: 5px;">
                下载众聚生活APP
              </div>
            </div>
            <div>
              <img src="../image/qr.jpg" style="width: 150px;height: 150px;">
            </div>
          </el-popover>


          <div class="dynamic">
            <div class="dynamic1">
              <div class="dynamic2">
                最新动态
              </div>
              <div class="dynamic3">
                <!-- 查看全部 -->
<!--                <el-button plain @click="checkAll()">查看全部</el-button>-->
              </div>
            </div>
            <!-- <div>
            <div style="display: flex;margin-top: 10px;">
              <div style="width: calc(66.6% - 5px);border-radius: 10px;background-color: #fff;margin-right: 10px;">
                <div>
                  <img
                    src="../image/img1.png"
                    style="width: 100%;height: 200px;border-radius: 10px 10px 0px 0px;"
                  >
                </div>
                <div style="padding: 10px;">
                  <div
                    class="tit"
                    style="text-align: left;"
                  >
                    {{ titile }}
                  </div>
                  <div
                    class="label"
                    style="text-align: left;"
                  >
                    {{ label }}
                  </div>
                  <div
                    class="time"
                    style="text-align: left;"
                  >
                    {{ time }}
                  </div>
                </div>
              </div>
              <div style="width: calc(33% - 5px);border-radius: 10px;background-color: #fff;">
                <div>
                  <img
                    src="../image/img1.png"
                    style="width: 100%;height: 200px;border-radius: 10px 10px 0px 0px;"
                  >
                </div>
                <div style="padding: 10px;">
                  <div
                    class="tit"
                    style="text-align: left;"
                  >
                    {{ titile }}
                  </div>
                  <div
                    class="label"
                    style="text-align: left;"
                  >
                    {{ label }}
                  </div>
                  <div
                    class="time"
                    style="text-align: left;"
                  >
                    {{ time }}
                  </div>
                </div>
              </div>
            </div>
          </div> -->
            <div  style="display: flex; justify-content: center;">
              <div class="grid-item" v-for="(item, index) in cardList" :key="index" @click="goDetail(item.id)">
                <div >
                  <div style="width: 100%;margin-top: 58px;">
                    <img :src="item.coverPic" style="width: 100%;height: 462px;border-radius: 10px;">
                  </div>
                  <div style="padding: 10px;">
                    <div class="tit" style="white-space: nowrap">
                      {{ item.title }}
                    </div>
                    <div class="label">
                      {{ item.keyDesc }}
                    </div>
                    <div class="time">
                      {{ item.createTime }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="grid-container">
                <div  class="grid-item"  v-for="(item, index1) in cardList2" :key="index1" @click="goDetail(item.id)">
                  <div  >
                    <div style="width: 100%;">
                      <img :src="item.coverPic" style="width: 356px;height: 230px;border-radius: 10px;">
                    </div>
                    <div style="padding: 10px;">
                      <div class="tit" style="white-space: nowrap">
                        {{ item.title }}
                      </div>
                      <div class="label">
                        {{ item.key_desc }}
                      </div>
                      <div class="time">
                        {{ item.createTime }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div class="butall">
              <!-- <div style="border-radius: 5px;border: 1px solid #909399;padding: 8px;" @click="checkAll()">
              查看全部
            </div> -->
              <el-button plain @click="checkAll()">查看全部</el-button>
            </div>
          </div>
        </div>

        <div style="width: 10%;"></div>
      </div>
      <Bottom></Bottom>
    </div>
    <div v-else>
      <div style="padding: 10px;background-color: #f3f4f6;">
        <div style="padding: 20px;display: flex;justify-content: center;align-items: center;">
          <div>
            <div class="textandio">
              <div style="font-size: 18px;font-weight: bold;color: #000;">
                众聚生活是一家科技零售公司
              </div>
            </div>
            <div style="margin: 10px 0px;">
              <div class="textandio">
                <div class="textlabandio1">
                  众聚生活以“科技+服务”的战略
                </div>
              </div>
              <div class="textandio">
                <div  class="textlabandio1">
                  践行“让实体店利益最大化，让消费者得到优质
                </div>
              </div>
              <div class="textandio">
                <div  class="textlabandio1">
                  的服务，让同城没有难做的生意”的公司使命。
                </div>
              </div>
            </div>
            <div>
              <div class="textandio">
                <div  class="textlabandio">
                  在本地实体经济的基础上迭代升级，
                </div>
              </div>
              <div class="textandio">
                <div  class="textlabandio">
                  再反哺到市场，倾力打造本地实体经济的命运共同体，
                </div>
              </div>
              <div class="textandio">
                <div  class="textlabandio">
                  力争做到取之于民用之于民，携手共做本地良知企业。
                </div>
              </div>
              <!-- <div class="textandio">
                <div  class="textlabandio">
                  更多创造社会价值。
                </div>
              </div> -->
            </div>
            <el-popover placement="bottom" width="150" trigger="hover">
            <!-- <el-button slot="reference">hover 激活</el-button> -->
            <div slot="reference" class="text1" style="font-size: 25px;color: #302f2f;margin-top: 25px;"
              @click="qrcode()">
              <!-- <i class="../image/iconerwm.png"></i> -->
              <img src="../image/iconerwm.png" style="width: 30px;height: 30px;">
              <div style="display: flex;align-items: center;margin-left: 5px;">
                下载众聚生活APP
              </div>
            </div>
            <div>
              <img src="../image/qr.jpg" style="width: 150px;height: 150px;">
            </div>
          </el-popover>
          </div>
        </div>
        <div>
          <div style="display: flex;">
            <div style="font-size: 18px;font-weight: bold;">
              最新动态
            </div>
            <div style="position: absolute;right: 20px;" @click="checkAll()">
              <div style="padding: 5px;border-radius: 5px;border: 1px solid #909399;font-size: 8px;">
                查看全部>
              </div>
              <!-- <el-button plain @click="checkAll()">查看全部</el-button> -->
            </div>
          </div>
          <div style="margin-top: 10px;">
            <div class="andList" v-for="(item, index) in cardList" :key="index" @click="goDetail(item.id)">
                <div style="background-color: white;border-radius: 10px;">
                  <div style="width: 100%;height: 200px;">
                    <img :src="item.coverPic" style="border-radius: 10px 10px 0px 0px;width: 100%;height: 200px;">
                  </div>
                  <div style="padding: 10px;">
                    <div style="font-size: 15px;color: #000;">
                      {{ item.title }}
                    </div>
                  </div>
                  <div style="padding: 0px 10px 10px 10px;">
                    <div style="font-size: 15px;color: #909399;">
                      {{ item.keyDesc }}
                    </div>
                    <div style="font-size: 13px;color: #909399;margin-top: 10px;">
                      {{ item.createTime }}
                    </div>
                  </div>
                </div>
            </div>
            <div class="andList" v-for="(item, index) in cardList2" :key="index" @click="goDetail(item.id)" style="margin-top: 10px;">
                <div style="background-color: white;border-radius: 10px;">
                  <div style="width: 100%;height: 200px;">
                    <img :src="item.coverPic" style="border-radius: 10px 10px 0px 0px;width: 100%;height: 200px;">
                  </div>
                  <div style="padding: 10px;">
                    <div style="font-size: 15px;color: #000;">
                      {{ item.title }}
                    </div>
                  </div>
                  <div style="padding: 0px 10px 10px 10px;">
                    <div style="font-size: 15px;color: #909399;">
                      {{ item.key_desc }}
                    </div>
                    <div style="font-size: 13px;color: #909399;">
                      {{ item.createTime }}
                    </div>
                  </div>

                </div>
            </div>
            <div style="display: flex;justify-content: center;align-items: center;margin: 10px 0px;" @click="checkAll()">
              <div style="padding: 5px;border-radius: 5px;border: 1px solid #909399;font-size: 8px;">
                查看全部>
              </div>
              <!-- <el-button plain @click="checkAll()">查看全部</el-button> -->
            </div>
          </div>
        </div>
        <BottomIphone></BottomIphone>
      </div>
    </div>
  </div>
</template>
<script>
import Bottom from "@/components/bottom.vue";
import BottomIphone from "../components/bottomiphone.vue";
export default {
  components: {
    Bottom,
    BottomIphone
   },
  //  components: {
  //     Top
  // },
  data() {
    return {
      activeIndex2: "1",
      activeIndex3: "0",
      titile: "众聚生活医药累计交易用户超三亿,携手药店药企构建15分钟医疗服务圈",
      label:
        "众聚生活持续推动服务零售和商品零售在需求侧和供给侧的数字化升级，和广大合作伙伴一起努力为消费者提供品质服务。2018年9月20日，众聚生活在港交所挂牌上市",
      time: "2024-05-10",
      cardList: [],
      cardList2: [],
      cardList1: [{
        coverPic:'../image/img3.png',
        key_desc:'',
        title:'',
        create_date:'',

      }],
      isFixed: false,
      offsetTop: 0,

      isMobile: false,
      isandio: false,
      ispc: false,
    };
  },
  mounted() {
    // this.loadScript();
    window.addEventListener('scroll', this.initHeight);
    this.$nextTick(() => {
      this.offsetTop = document.querySelector('#boxFixed').offsetTop;
    })
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll)
  },
  created() {
    // this.detectDeviceType();
    console.log('首页加载')
    this.detectDeviceType()
    this.articleList()
  },
  methods: {
    detectDeviceType() {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;
      // Regular expressions to match mobile devices
      const mobileRegex = /android|iphone|ipad|ipod|blackberry|iemobile|opera mini/i;
      this.ispc = !mobileRegex.test(userAgent);
    },
    // detectDeviceType() {
    //   // 使用正则表达式来检测用户代理字符串
    //   // 注意：这个正则表达式可能需要根据实际需求进行调整
    //   const userAgent = navigator.userAgent.toLowerCase();
    //   this.isMobile = /iphone|ipad|ipod|android|blackberry|mini|windows\sce|palm/i.test(userAgent);
    // },

    // loadScript() {
    //   var _53code = document.createElement("script");
    //   _53code.src = "https://tb.53kf.com/code/code/94f79a2bfc7f24072d50f992ffee87209/1";
    //   var s = document.getElementsByTagName("script")[0];
    //   s.parentNode.insertBefore(_53code, s);
    // },
    initHeight() {
      var scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      this.isFixed = scrollTop > this.offsetTop ? true : false;
    },
    handleSelect(key, keyPath) {
      console.log(key, keyPath);
      if (keyPath == 1) {
        this.$router.push('/')
      } else if (keyPath == 2) {
        this.$router.push('/cooperation')
      }

    },
    handleSelect1(key, keyPath) {
      console.log(key, keyPath);
    },
    qrcode() {
      // 二维码
    },
    articleList() {
      this.axios({
        methods: "get",
        url: "/oldarticle/article/listByPage",
        // url: "http://192.168.0.196/kuyan/takeaway/public/index.php/article/getArticleList",
        params: {
          page: "1",
          page_size: "10",
        },
      }).then((response) => {
        // 处理成功情况
        console.log(response.data.data.records);
        // 你可以将response.data赋值给data中的某个属性，用于渲染
        this.cardList = response.data.data.records.slice(0, 1)
        this.cardList2 = response.data.data.records.slice(1, 5)
      })
        .catch((error) => {
          // 处理错误情况
          console.error("请求失败:", error);
        });
    },
    checkAll() {
      // this.$route.push('/articleList')
      this.$router.push({ path: '/articleList' })
    },
    goDetail(index) {
      // this.$router.push({ path: '/articleDetail' })
      this.$router.push({ path: '/articleDetail', query: { id: index } })
    },
  },
};
</script>
<style scoped>
.page {
  /* background-color: #f3f4f6; */
  /* background: linear-gradient(rgb(248, 244, 2), rgb(255, 254, 254)); */
  /* background-image: url("https://s3plus.meituan.net/smart/halfbg2.svg"); */
  /* background-image: url("https://s3plus.meituan.net/smart/halfbg2.svg"),
                  url('https://s3plus.meituan.net/smart/left-trans.svg'),
                  url('https://s3plus.meituan.net/smart/right2.svg');
background-repeat: no-repeat, no-repeat, no-repeat;
background-position: center top, left top, right top; */
  //background-image: url("../image/bj.png");
  background-repeat: no-repeat;
  /*设置背景不重复*/
  background-attachment: fixed;
  /*背景图片不会随着页面的滚动而滚动。*/
}

body {
  margin: 0 !important;
}

.top {
  padding: 20px;
  /* background-color: #fff; */
  /* backdrop-filter: blur(20px); */
  display: flex;
  background-color: rgba(255, 255, 255, 0.6);
}

.is_fixed {
  position: fixed;
  top: 0;
  z-index: 999;
  width: 100%;
}

.el-menu {
  background-color: transparent !important;
}

.el-menu.el-menu--horizontal {
  border: 0px !important;
}

.middle {
  display: flex;
  padding: 20px 0px;
  /* background-color: #0aee43; */
}

@media (max-width: 768px) {
  .middle {
    /* background-color: lightblue; */
    display: flex;
    padding: 10px 0px;
  }
}

/* .middle1 {
  display: flex;
  padding: 10px 0px;
  background-color: #eeea0a;
} */
.text1 {
  display: flex;
  justify-content: center;
  align-items: center;
}

.dynamic {
  margin-top: 186px;
}

.dynamic1 {
  display: flex;
  margin-bottom: 20px;
}

.dynamic2 {
  font-size: 25px;
  font-weight: bold;
  color: #000;
}

.dynamic3 {
  position: absolute;
  right: calc(20% + 10px);
}

.tit {
  font-size: 20px;
  font-weight: bold;
  color: #000;
}

.label {
  font-size: 15px;
  color: #909399;
  margin: 10px 0px;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.time {
  font-size: 13px;
  color: #bfc2c7;
  margin-top: 20px;
  position: absolute;
  bottom: 0;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  /* 创建三列，每列宽度相等 */
  /* grid-template-rows: repeat(2, 1fr); 创建三行，每行高度相等 */
  gap: 10px;
  /* 格子之间的间隙 */
  /* height: 300px; 容器高度 */
  /* 容器居中显示 */
  margin-top: 58px;
}

.grid-item {
  background-color: #fff;
  /* 格子背景色 */
  justify-content: center;
  /* 水平居中 */
  align-items: center;
  /* 垂直居中 */
  font-size: 20px;
  /* 字体大小 */
  border-radius: 10px;
  position: relative;
  margin-right: 20px;
  margin-bottom: 20px;
}

.imga {
  width: 100%;
  height: 200px;
  border-radius: 10px 10px 0px 0px;
}

.butall {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
}

.textandio {
  display: flex;
  justify-content: center;
  align-items: center;
}
.textlabandio{
  font-size: 13px;
  color: #909399;
  margin-bottom: 5px;
}
.textlabandio1{
  font-size: 15px;
  color: #66686b;
  margin-bottom: 5px;
}
</style>
